import moment from 'moment'
export default {
    name: 'ReservePopupBox',
    data () {
        return {
            showPop: false,
            resultList: []
        }
    },
    props: {
        loading: {
          type: Boolean,
          default: false
        },
        list: {
            type: Array,
            default: () => []
        },
        currentItem: {
            type: Object,
            default: null
        },
        // 筛选显示到类型
        businessType: {
            type: Number,
            default: 0 // 1.机票，2.火车票，3.酒店
        },
        disable: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    created () {},
    mounted () {
        document.addEventListener('click', this.clickReserveWrapper)
        this.setResultList()
    },
    activated () {
    },
    deactivated() {},
    destroyed () {
        document.removeEventListener('click', this.clickReserveWrapper)
    },
    watch: {
        list(newVal) {
            let resultList = []
            newVal.forEach(value => {
                let result = this.isShowItem(value)
                if (result) {
                    resultList.push(value)
                }
            })
            this.resultList = resultList
        },
        disable(newVal) {
            if (newVal) {
                this.current = null
                this.showPop = false
            }
        },
    },
    computed: {
        current: {
            get () {
                return this.currentItem
            },
            set (val) {
                this.$emit('update:currentItem', val)
            }
        }
    },
    filters: {
        changeDepartTime(val) {
            let day = moment(val).format('YYYY年MM月DD日')
            return day
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
    },
    methods: {
        setBusinessName(val) {
            let text = '';
            switch (val) {
                case 1:
                    text = '机票';
                    break
                case 2:
                    text = '火车票';
                    break
                case 3:
                    text = '酒店';
                    break
                default:
                    text = ''
            }
            return text;
        },
        setResultList() {
            let resultList = []
            this.list.forEach(value => {
                let result = this.isShowItem(value)
                if (result) {
                    resultList.push(value)
                }
            })
            this.resultList = resultList
        },
        toggleShowPop() {
            if (this.disable) {
                return
            }
            this.showPop = !this.showPop
        },
        clickReserveWrapper(e) {
            if (!!this.$refs.reserveChooseBox.contains(e.target)) return
            this.showPop = false
        },
        getCurrentItem(item, journey, index, jIndex) {
            let current = JSON.parse(JSON.stringify(item));
            current.active = journey
            this.current = current
            this.toggleShowPop()
            this.$emit('chooseJourney', current)
        },
        isShowItem(item) {
            let haveStaffList = false
            item.journeys.forEach(value => {
                if (value.staffList.length > 0) {
                    haveStaffList = true
                }
            })
            if (this.businessType === 0) {
                return haveStaffList
            } else {
                let show = false
                item.journeys.forEach(value => {
                    if (value.businessType === this.businessType) {
                        show = true
                    }
                })
                return show && haveStaffList
            }
        },
        del() {
            this.current = null
            this.$emit('chooseJourney', this.current)
        },
        isShowJourney(journey) {
            let isThisBusinessType = (this.businessType === 0 || journey.businessType === this.businessType)
            // 就算没有可选的人也要展示
            // let isHasPassUser = false
            // journey.staffList.forEach(value => {
            //     if (value.bookStatus === 1) {
            //         isHasPassUser = true
            //     }
            // })
            // return isThisBusinessType && isHasPassUser
            return isThisBusinessType
        },
        setActiveItemName(activeItem) {
            if (!activeItem) {
                return ''
            }
            let name = ''
            name += `${activeItem.evectionReason}-`
            let businessName = ''
            if (activeItem.active.businessType === 1) {
                businessName = '机票'
            } else if (activeItem.active.businessType === 2) {
                businessName = '火车票'
            } else {
                businessName = '酒店'
            }
            name += `[${businessName}] `
            if (activeItem.active.businessType === 3) {
                name += `${activeItem.active.departCityName || activeItem.active.toCityName}`
            } else {
                name += `${activeItem.active.departCityName}-${activeItem.active.toCityName}`
            }
            return name
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
